@charset "UTF-8";
/**
    CONTROL
 */
.dc-control-container {
  padding: 10px 15px;
  border-radius: 4px;
}
.dc-control-container.dc-control-clone.sticky {
  position: fixed;
  width: 100%;
  z-index: 13 !important;
}
.dc-control-container .dc-btn-panel, .dc-control-container .dc-btn-group, .dc-control-container .dt-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
}
.dc-control-container .dc-btn-panel > .dc-button, .dc-control-container .dc-btn-group > .dc-button, .dc-control-container .dt-buttons > .dc-button {
  border: none !important;
  margin: 0 !important;
  border-radius: 0;
  /* -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 10px 20px rgba(0, 0, 0, 0.24) !important; */
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}
.dc-control-container .dc-btn-panel > .dc-button > i, .dc-control-container .dc-btn-group > .dc-button > i, .dc-control-container .dt-buttons > .dc-button > i {
  color: white;
  font-size: 1rem;
}
.dc-control-container .dc-btn-panel > .dc-button:hover, .dc-control-container .dc-btn-group > .dc-button:hover, .dc-control-container .dt-buttons > .dc-button:hover {
  /*background-color: #0333e8;*/
  border: 0 !important;
  /*margin: 0 !important;*/
  /* -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23) !important; */
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}
.dc-control-container table thead th {
  border: 0;
  padding: 5px;
  min-width: 50px;
  position: relative;
}
.dc-control-container table thead tr:first-child th {
  padding: 10px 15px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.dc-control-container table thead tr:first-child th.sort {
  cursor: pointer;
}
.dc-control-container table thead .dc-sort:before {
  right: 10px;
  content: "↑";
  position: absolute;
  bottom: 10px;
  display: block;
  opacity: 0.3;
}
.dc-control-container table thead .dc-sort:after {
  right: 15px;
  content: "↓";
  position: absolute;
  bottom: 10px;
  display: block;
  opacity: 0.3;
}
.dc-control-container table thead .dc-sort.sort_asc:after {
  opacity: 1;
}
.dc-control-container table thead .dc-sort.sort_desc:before {
  opacity: 1;
}

/*


//.dc-control-container  {
//    .dc-btn-group, .dt-buttons{
//
//        .dc-button.btn,  .btn {
//            //opacity:1;
//            background-color: $color-theme;
//            border: none !important;
//            margin: 0 !important;
//            border-radius: 0;
//            !* -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 10px 20px rgba(0, 0, 0, 0.24) !important; *!
//            -moz-box-shadow: none !important;
//            -ms-box-shadow: none !important;
//            -o-box-shadow: none !important;
//            box-shadow: none !important;
//
//            &:first-child{
//                border-top-left-radius: 4px;
//                border-bottom-left-radius: 4px;
//            }
//
//            &:last-child{
//                border-top-right-radius: 4px;
//                border-bottom-right-radius: 4px;
//            }
//
//            &:disabled{
//                background-color: rgba($color-theme, 0.6);
//                cursor: default;
//            }
//
//            &:hover {
//                !*background-color: #0333e8;*!
//                //opacity:1;
//                border: 0 !important;
//                !*margin: 0 !important;*!
//                !* -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23) !important; *!
//                -moz-box-shadow: none !important;
//                -ms-box-shadow: none !important;
//                -o-box-shadow: none !important;
//                box-shadow: none !important;
//            }
//        }
//
//
//    }
//
//
//
//}
//
*/
.dc-btn-panel .dc-button > a {
  color: unset !important;
}

.dc-control-filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  display: inline-block;
}

.dc-control-filter input, .dc-control-filter select {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

/**
    END CONTROL
 */
